
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "onboard",
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Onboard");
    });
  },
});
