import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-10" }
const _hoisted_2 = { class: "card-body pt-9 pb-0" }
const _hoisted_3 = { class: "d-flex flex-wrap flex-sm-nowrap mb-3" }
const _hoisted_4 = { class: "flex-grow-1" }
const _hoisted_5 = { class: "d-flex justify-content-between align-items-start flex-wrap mb-2" }
const _hoisted_6 = { class: "d-flex flex-column" }
const _hoisted_7 = { class: "d-flex align-items-center mb-2" }
const _hoisted_8 = {
  href: "#",
  class: "text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("a", _hoisted_8, _toDisplayString(this.$store.state.AuthModule.account.name), 1)
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_router_view)
  ], 64))
}